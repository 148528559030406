import { getToken, baseUrl } from "./index";
import fetch from "isomorphic-fetch";

/**
 * Get all comments for an object, given its association_type and association_id
 * @param {string} object
 * @param {number} id
 */
export const getComments = async (object, id) =>
  fetch(`${baseUrl}/comments/${object}/${id}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(res => res.json());

/**
 * Post a comment against an object, given it's association_type and association_id
 * @param {string} object
 * @param {number} id
 * @param {string} body
 */
export const addComments = async (object, id, text) =>
  fetch(`${baseUrl}/comments/${object}/${id}`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ text })
  }).then(res => res.json());

/**
 * Delete a comment, given its id
 * @param {number} commentId
 */
export const deleteComment = async commentId =>
  fetch(`${baseUrl}/comments/${commentId}`, {
    method: "DELETE",
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(resp => resp.json());
