import {
  faAddressBook,
  faChartLine,
  faCreditCard,
  faFileExcel,
  faList,
  faPlusCircle,
  faSignOutAlt,
  faUpload,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

import ClientSelector from "../ClientSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import React from "react";
import { auth } from "firebase/app";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Navigation(props) {
  const history = useHistory();
  const accessor = useSelector(state => state.accessor);
  const dropDownItem = (icon, text, to, preFn) => (
    <NavDropdown.Item
      onSelect={() => {
        if (preFn) preFn();
        history.push(`/${to}`);
      }}>
      <FontAwesomeIcon icon={icon} style={{ marginRight: "10px" }} />
      {text}
    </NavDropdown.Item>
  );
  const isUserAdmin = accessor.groups.some(group =>
    ["Developer admin", "admin"].includes(group),
  );

  const isUserAccountsOrAdmin = accessor.groups.some(group =>
    ["accounts", "admin"].includes(group),
  );

  const isUserCollections = accessor.groups.some(group =>
    ["accounts", "collections"].includes(group),
  );

  const profileDropDown = (
    <NavDropdown
      id="profile-dropdown"
      alignRight={true}
      title={
        <img className="avatar profile-pic" src={accessor.imgUrl} />
      }>
      <NavDropdown.Item onClick={() => auth().signOut()}>
        <FontAwesomeIcon
          icon={faSignOutAlt}
          style={{ marginRight: "10px" }}
        />
        Logout
      </NavDropdown.Item>
    </NavDropdown>
  );

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      sticky="top"
      style={{ background: "#FFFFFF" }}>
      <Navbar.Brand
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.push("/");
        }}>
        Artool.app
      </Navbar.Brand>
      <Form inline>
        <ClientSelector />
      </Form>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav className="mr-auto">
          <NavDropdown title="Invoices">
            {isUserAccountsOrAdmin &&
              dropDownItem(faPlusCircle, "Add", "new-invoice")}

            {dropDownItem(faList, "View", "invoices")}
            {dropDownItem(faFileExcel, "Pivot", "pivot")}
            {dropDownItem(faUpload, "Upload", "invoice-upload")}
          </NavDropdown>
          <NavDropdown title="Payments">
            {isUserAccountsOrAdmin &&
              dropDownItem(faCreditCard, "Add", "new-payment")}
            {dropDownItem(faList, "View", "payments")}
            {dropDownItem(faChartLine, "Trends", "payment-trends")}
          </NavDropdown>

          <NavDropdown title="Master Data">
            {isUserAdmin && dropDownItem(faUsers, "Users", "users")}
            {dropDownItem(
              faAddressBook,
              "Company groups",
              "customers",
            )}
          </NavDropdown>
          {window.innerWidth < 768 && profileDropDown}
        </Nav>
      </Navbar.Collapse>
      {window.innerWidth >= 768 && profileDropDown}
    </Navbar>
  );
}
