import moment from "moment";

import {
  UPDATE_ALERT,
  UPDATE_CONFIRM_PROMPT,
  UPDATE_ACCESSOR,
  UPDATE_CLIENT,
  UPDATE_COLLECTOR,
  UPDATE_COMPANY,
  UPDATE_CUSTOMER,
  UPDATE_ENTITY,
  UPDATE_FILTER_INVOICE_DATE,
  UPDATE_FILTER_PAYMENT_DATE,
  UPDATE_SOCKET,
  UPDATE_CURRENCY,
  UPDATE_LOCALE
} from "../../constants";

const invoiceDateRange = new URLSearchParams(window.location.search).get(
  "invoiceDateRange[]"
);
const initialState = {
  client: {
    clientId: null,
    locale: null,
    currency: null
  },
  collectorId: null,
  companyId: null,
  billableEntity: null,
  customerId: null,
  alert: {
    show: false,
    variant: "success",
    header: "",
    body: ""
  },
  confirm: {
    show: false,
    header: "",
    body: "",
    onConfirm: () => {},
    onCancel: () => {}
  },
  filters: {},
  invoiceDateRange: {
    startDate: invoiceDateRange
      ? moment(invoiceDateRange.replace("/", ",").split(",")[0])
      : null,
    endDate: invoiceDateRange
      ? moment(invoiceDateRange.replace("/", ",").split(",")[1])
      : null
  },
  paymentDateRange: {
    startDate: null,
    endDate: null
  },
  socket: null
};

const rootReducer = (state = initialState, action) =>
  [
    UPDATE_ALERT,
    UPDATE_ACCESSOR,
    UPDATE_CLIENT,
    UPDATE_COLLECTOR,
    UPDATE_COMPANY,
    UPDATE_CUSTOMER,
    UPDATE_ENTITY,
    UPDATE_CONFIRM_PROMPT,
    UPDATE_FILTER_INVOICE_DATE,
    UPDATE_FILTER_PAYMENT_DATE,
    UPDATE_SOCKET,
    UPDATE_CURRENCY,
    UPDATE_LOCALE
    // Array of actions here
  ].includes(action.type)
    ? { ...state, ...action.payload }
    : state;

export default rootReducer;
