import { baseUrl, getFilters, getToken } from "./index";

import fetch from "isomorphic-fetch";
import moment from "moment";

/**
 * 
 * @param {{billableEntityId:number,
    collectorId:number,
    invoiceNumber:string,
    invoiceDate: string,
    amount:number,
    creditPeriod:number,
    clientId:number}} invoiceData
 */
export const createInvoice = async invoiceData =>
  fetch(`${baseUrl}/invoices`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(invoiceData),
  });
/**
 *
 * @param {*} param0
 */
export const getInvoices = async ({
  pageSize,
  pageNumber,
  clientId,
  customerId,
  companyId,
  collectorId,
  entityId,
  status,
  search,
  exact,
  sortColumn,
  sortOrder,
  invoiceDateRange,
  paymentDateRange,
  getComments,
}) =>
  fetch(
    `${baseUrl}/invoices${getFilters({
      pageSize,
      pageNumber,
      clientId,
      status,
      search,
      exact,
      sortColumn,
      sortOrder,
      customerId,
      collectorId,
      getComments,
    })}${
      invoiceDateRange
        ? `&invoiceDateRange[]=${invoiceDateRange.startDate.format(
            "YYYY-MM-DD",
          )}&invoiceDateRange[]=${invoiceDateRange.endDate.format(
            "YYYY-MM-DD",
          )}`
        : ``
    }${
      paymentDateRange && paymentDateRange.startDate
        ? `&paymentDateRange[]=${paymentDateRange.startDate.format(
            "YYYY-MM-DD",
          )}&paymentDateRange[]=${paymentDateRange.endDate.format(
            "YYYY-MM-DD",
          )}`
        : ``
    }`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`,
      },
    },
  ).then(res => res.json());
/**
 *
 * @param {number} id
 */
export const getInvoice = async id =>
  fetch(`${baseUrl}/invoices/${id}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`,
    },
  }).then(res => res.json());
/**
 *
 * @param {number} id
 * @param {{submissionDate: string}} payload
 */
export const updateInvoice = async (id, payload) =>
  fetch(`${baseUrl}/invoices/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

/**
 *
 * @param {number} clientId
 */
export const getInvoiceSummary = async ({
  clientId,
  search,
  invoiceDateRange,
  paymentDateRange,
  customerId,
  collectorId,
}) =>
  fetch(
    `${baseUrl}/invoices/summary${getFilters({
      clientId,
      search,
      customerId,
      collectorId,
    })}${
      invoiceDateRange
        ? `&invoiceDateRange[]=${invoiceDateRange.startDate.format(
            "YYYY-MM-DD",
          )}&invoiceDateRange[]=${invoiceDateRange.endDate.format(
            "YYYY-MM-DD",
          )}`
        : ``
    }${
      paymentDateRange
        ? `&paymentDateRange[]=${paymentDateRange.startDate.format(
            "YYYY-MM-DD",
          )}&paymentDateRange[]=${paymentDateRange.endDate.format(
            "YYYY-MM-DD",
          )}`
        : ``
    }`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`,
      },
    },
  ).then(res => res.json());

export const getPivot = async (
  clientId,
  buckets,
  customerId,
  collectorId,
  dimensionIndices,
  date,
) =>
  fetch(
    `${baseUrl}/invoices/pivot${getFilters({
      clientId,
      buckets,
      customerId,
      collectorId,
      dimensionIndices,
      date,
    })}`,
    {
      headers: { Authorization: `bearer ${await getToken()}` },
    },
  ).then(res => res.json());

/**
 *
 * @param {{collectionDateRange:string[],dimension:number}} parameters
 */
export const getExpectedCollections = async ({
  collectionDateRange,
  dimension,
  customerId,
  collectorId,
}) =>
  fetch(
    `${baseUrl}/invoices/expected${getFilters({
      dimension,
      customerId,
      collectorId,
      collectionDateRange,
    })}`,
    {
      headers: { Authorization: `bearer ${await getToken()}` },
    },
  ).then(res => res.json());
/**
 *
 * @param {number} id
 */
export const getPaymentsForInvoice = async id =>
  fetch(`${baseUrl}/invoices/${id}/payments`, {
    headers: { Authorization: `bearer ${await getToken()}` },
  }).then(res => res.json());
/**
 *
 * @param {{customerId: number, ,collectorId:number}} param0
 */
export const getMetaStats = async ({
  clientId,
  customerId,
  collectorId,
}) =>
  fetch(
    `${baseUrl}/invoices/meta${getFilters({
      clientId,
      customerId,
      collectorId,
    })}`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`,
      },
    },
  ).then(res => res.json());

/**
 *
 * @param {{invoiceDateRange:string[],dimension}} parameters {
 *  invoiceDateRange: two dates to filter between
 *  dimension: 7, 8, 9
 * }
 */
export const getTrends = async ({ invoiceDateRange, dimension }) =>
  fetch(
    `${baseUrl}/invoices/trends${getFilters({
      invoiceDateRange,
      dimension,
    })}`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`,
      },
    },
  ).then(res => res.json());
/**
 *
 * @param {number} id
 */
export const deleteInvoice = async id =>
  fetch(`${baseUrl}/invoices/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `bearer ${await getToken()}`,
    },
  }).then(res => res.json());

/**
 *
 * @param {number} id
 */
export const writeOffInvoice = async id =>
  fetch(`${baseUrl}/invoices/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ cannotPay: true }),
  });
/**
 *
 * @param {number} id
 */
export const unwriteOffInvoice = async id =>
  fetch(`${baseUrl}/invoices/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ cannotPay: false }),
  });

export const uploadInvoices = async ({ file }) => {
  const body = new FormData();
  body.append("invoices", file, file.name);
  return fetch(`${baseUrl}/invoices/upload`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
    },
    body,
  }).then(response => response.json());
};
