import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { updateClient, showSuccessAlert } from "../../redux/actions";

import { getClients } from "../../Api";

import Select from "react-select";

const hasGroups = (checkGroups, groups) =>
  checkGroups.some(cGroup => groups.some(group => group === cGroup));

export default function ClientSelector(props) {
  const clientId = useSelector(state => state.client.clientId);
  const [clientList, setClientList] = useState([]);
  const dispatch = useDispatch();
  const accessor = useSelector(state => state.accessor);
  const getClientNames = async () => {
    if (hasGroups(accessor.groups, ["Developer admin"])) {
      const clientNames = await getClients();
      setClientList(clientNames.clients);
    }
  };
  useEffect(() => {
    getClientNames();
  }, []);

  const clientOptions = clientList.map(client => ({
    value: client.id,
    label: client.name
  }));

  const customStyles = {
    container: styles => ({ ...styles, width: "200px", height: "40px" }),
    control: styles => ({ ...styles, height: "40px", borderColor: "#dbdbdb" }),
    menu: styles => ({ ...styles, width: "300px", zIndex: "1000" }),
    menuList: styles => ({ ...styles, width: "300px", zIndex: "1000" }),
    option: (styles, state) => ({
      ...styles,
      background: state.isSelected
        ? "#439fd9"
        : state.isFocused
        ? "#c7e9ff"
        : "#FFFFFF"
    })
  };

  return hasGroups(accessor.groups, ["Developer admin"]) ? (
    <Select
      id={props.id || "client-selector"}
      styles={customStyles}
      isDisabled={props.isDisabled}
      placeholder="Select Client"
      options={clientOptions}
      value={clientOptions.find(client => client.value === clientId)}
      isClearable={true}
      className="dropdown client-selector"
      onChange={e => {
        dispatch(updateClient(e ? e.value : ""));
        dispatch(
          showSuccessAlert(
            `Switching client context to ${e ? e.label : `"none"`}.`
          )
        );
      }}
    />
  ) : (
    <></>
  );
}
