import { getToken, baseUrl } from "./index";
import fetch from "isomorphic-fetch";

/**
 * Get Users
 * @param {number} clientId
 */

export const getUsers = async ({
  pageSize,
  pageNumber,
  search,
  groupId,
  clientId
}) =>
  fetch(
    `${baseUrl}/users?pageSize=${pageSize}&pageNumber=${pageNumber}${
      search && search.length > 2 ? `&search=${search}` : ``
    }${groupId ? `&groupId=${groupId}` : ``}${
      clientId ? `&clientId=${clientId}` : ``
    }`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(response => response.json());

export const getCollectors = async () =>
  await getUsers({ pageSize: 1000, pageNumber: 0, groupId: 6 });

/**
 * Get user details from email
 * @param {string} email
 */

export const getUser = async () =>
  fetch(`${baseUrl}/users/accessor`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  })
    .then(response => response.json())
    .catch(error => {
      console.log(error);
      return {
        error: "Something went wrong"
      };
    });

/**
 *
 * @param {*} userData
 */
export const addUser = async userData =>
  await fetch(`${baseUrl}/users`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(userData)
  }).then(response => response.json());

/**
 * Add Group to user
 * @param {number} userId
 * @param {number} groupId
 */

export const addGroupToUser = async (userId, groupId) =>
  fetch(`${baseUrl}/users/${userId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ groupId, operation: "add" })
  }).then(response => response.json());

/**
 *
 * @param {*} userId
 */
export const setLatestAccessTime = async userId =>
  fetch(`${baseUrl}/users/${userId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ operation: "setAccessTime" })
  }).then(response => response.json());
/**
 * Remove Group from user
 * @param {number} userId
 * @param {number} groupId
 */

export const removeGroupFromUser = async (userId, groupId) =>
  fetch(`${baseUrl}/users/${userId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ groupId, operation: "remove" })
  }).then(response => response.json());

/**
 * Activate user
 * @param {number} userId
 */

export const activateUser = async userId =>
  fetch(`${baseUrl}/users/${userId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ operation: "activate" })
  });

/**
 * Deactivate user
 * @param {number} userId
 */

export const deactivateUser = async userId =>
  fetch(`${baseUrl}/users/${userId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ operation: "deactivate" })
  });

/**
 *
 * @param {number} userId
 * @param {string} name
 */

export const renameUser = async (userId, name) =>
  fetch(`${baseUrl}/users/${userId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ operation: "rename", name })
  });

/**
 *
 * @param {file} param0
 */

export const uploadUsers = async ({ file }) => {
  const body = new FormData();
  body.append("userList", file, file.name);
  return fetch(`${baseUrl}/users/upload`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`
    },
    body
  }).then(response => response.json());
};
