import "firebase/auth";
import "./App.css";

import React, { Suspense, lazy, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { getToken, getUser, setLatestAccessTime } from "./Api";
import {
  showErrorAlert,
  updateAccessor,
  updateClient,
  updateClientLocale,
  updateCollector,
  updateCurrency,
  updateSocket,
} from "./redux/actions";
import { useDispatch, useSelector } from "react-redux";

import ConfirmPrompt from "./components/ConfirmPrompt";
import { Helmet } from "react-helmet";
import Navigation from "./components/Navigation";
import { SERVER_APP_URL } from "./config.json";
import firebase from "firebase/app";
import ioClient from "socket.io-client";
import pages from "./components/pages";
import { parse } from "querystring";

const LoginPage = lazy(() => import("./pages/LoginPage"));
const HomePage = lazy(() => import("./pages/HomePage"));
const InvoicesListPage = lazy(() =>
  import("./pages/InvoicesListPage"),
);
const PaymentsListPage = lazy(() =>
  import("./pages/PaymentsListPage"),
);
const PaymentTrendsPage = lazy(() =>
  import("./pages/PaymentTrendsPage"),
);
const CustomersPage = lazy(() => import("./pages/CustomersPage"));
const CustomerPage = lazy(() => import("./pages/CustomerPage"));
const CompanyPage = lazy(() => import("./pages/CompanyPage"));
const UsersPage = lazy(() => import("./pages/UsersPage"));
const NewInvoicePage = lazy(() => import("./pages/NewInvoicePage"));
const NewPaymentPage = lazy(() => import("./pages/NewPaymentPage"));
const InvoicePage = lazy(() => import("./pages/InvoicePage"));
const PaymentPage = lazy(() => import("./pages/PaymentPage"));
const PivotViewPage = lazy(() => import("./pages/NewPivotPage"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const InvoiceUploadPage = lazy(() =>
  import("./pages/InvoiceUploadPage"),
);

function App(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const accessor = useSelector(state => state.accessor);

  const listenToAuthStateChanges = async () => {
    firebase.auth().onAuthStateChanged(async fbUser => {
      // to handle Redirect and URL Parameters

      let { redirect, ...propObject } = parse(
        location.search.slice(1),
      );
      if (propObject.error)
        dispatch(showErrorAlert(propObject.error));

      if (fbUser) {
        const { user } = await getUser(fbUser.email);

        if (user) {
          const isUserCollector = user.groups.some(
            group => group === "collections",
          );
          const isUserCollectionsHead = user.groups.some(
            group => group === "head_collections",
          );
          if (isUserCollector && !isUserCollectionsHead)
            dispatch(
              updateCollector({ value: user.id, label: user.name }),
            );
          if (user)
            dispatch(
              updateAccessor({ ...user, imgUrl: fbUser.photoURL }),
            ); // update redux
          if (user.clientId)
            dispatch(
              updateClient({
                clientId: user.clientId,
                locale: user.locale,
                currency: user.currency,
              }),
            );

          if (user.id) setLatestAccessTime(user.id);
          getToken().then(token => {
            const socket = ioClient(SERVER_APP_URL, {
              query: {
                token,
              },
            });
            socket.on("connect", () => {
              // If server acknowledgment is transmitted to client, store socket as redux state
              dispatch(updateSocket(socket));
            });
          });
        } else {
          dispatch(
            showErrorAlert("Access Denied. User not created yet."),
          );
          history.push("/");
        }
        // if in login page redirect the user to the redirect OR home page
        if (window.location.pathname === "/login")
          history.push(
            `/${redirect || ""}${
              Object.entries(propObject).length > 0
                ? `?${Object.entries(propObject)
                    .map(([key, value]) => `${key}=${value}`)
                    .join("&")}`
                : ``
            }`,
          );
      } else if (window.location.pathname !== "/login")
        // if in any other page redirect the user to the login page
        history.push("/login");
    });
  };

  useEffect(() => {
    listenToAuthStateChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App bootstrap">
      <Helmet>
        <title>
          Artool - Accounts receivable tracking & reporting
        </title>
        <meta
          name="description"
          content={`ARTool helps you stay on top of your receivables, by providing you reports and workflows around your A/R Lifecycle, thus shortening your Invoice To Cash timelines. `}
        />
      </Helmet>
      <Suspense fallback={<div>Loading....</div>}>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <ProtectedRoute exact path="/users" component={UsersPage} />
          <ProtectedRoute
            exact
            path="/customers"
            component={CustomersPage}
          />
          <ProtectedRoute
            exact
            path="/customer/:customerId"
            component={CustomerPage}
          />
          <ProtectedRoute
            exact
            path="/company/:companyId"
            component={CompanyPage}
          />
          <ProtectedRoute
            exact
            path="/invoice-upload"
            component={InvoiceUploadPage}
          />
          <ProtectedRoute
            exact
            path="/invoices"
            component={InvoicesListPage}
          />
          <ProtectedRoute
            exact
            path="/payments"
            component={PaymentsListPage}
          />
          <ProtectedRoute
            exact
            path="/payment-trends"
            component={PaymentTrendsPage}
          />
          <ProtectedRoute
            exact
            path="/invoice/:invoiceId"
            component={InvoicePage}
          />
          <ProtectedRoute
            exact
            path="/payment/:paymentId"
            component={PaymentPage}
          />
          <ProtectedRoute
            exact
            path="/pivot"
            component={PivotViewPage}
          />
          <ProtectedRoute
            exact
            path="/new-invoice"
            component={NewInvoicePage}
          />
          <ProtectedRoute
            exact
            path="/new-payment"
            component={NewPaymentPage}
          />
          <ProtectedRoute exact path="/" component={HomePage} />

          <Route path="*" component={ErrorPage} />
        </Switch>
      </Suspense>
      <ConfirmPrompt />
    </div>
  );
}

const ProtectedRoute = ({ path, component, ...props }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let propObject = parse(location.search.slice(1));
  const accessor = useSelector(state => state.accessor);
  const userGroups = accessor && accessor.groups;

  // Wait for groups
  if (!userGroups) return <>Waiting to sign you in</>;
  // Authenticated user -> go to route, while waiting for group
  else if (
    accessor &&
    !accessor.deactivatedAt &&
    (path === "/" || userHasAccess(path, userGroups))
  )
    return (
      <>
        <Navigation />
        <Route exact path={path} component={component} {...props} />
      </>
    );
  else if (accessor && accessor.deactivatedAt)
    return (
      <>
        <Navigation />
        <ErrorPage />
      </>
    );
  // Unauthenticated user -> go to login path, but redirect to requested page once logged in
  else if (accessor) {
    dispatch(showErrorAlert("You don't have access to this page"));
    return <Redirect to="/" />;
  } else {
    return (
      <Redirect
        to={`/login?redirect=${path.slice(1, 100)}${Object.entries(
          propObject,
        )
          .map(([key, value]) => `&${key}=${value}`)
          .join("")}`}
      />
    );
  }
};

// Check if user groups has access to roles defined
const userHasAccess = (path, userGroups) =>
  pages
    .find(page => page.path === path.split("/")[1])
    .groups.some(group =>
      userGroups.some(userGroup => userGroup === group),
    );

export default App;
