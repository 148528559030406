import { getToken, baseUrl, getFilters } from "./index";
import fetch from "isomorphic-fetch";

/**
 *
 * @param {{companyId: number, name: string, taxNumber: string,address: string}} param0
 */

export const addBillableEntityToCompany = async ({
  companyId,
  name,
  taxNumber,
  address
}) =>
  fetch(`${baseUrl}/entities/`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ companyId, name, taxNumber, address })
  });

/**
 *
 * @param {{pageSize: number, pageNumber: number, companyId: number, clientId: number, search: string}} param0
 */
export const getBillableEntities = async ({
  pageSize,
  pageNumber,
  companyId,
  clientId,
  search
}) =>
  fetch(
    `${baseUrl}/entities${getFilters({
      pageSize,
      pageNumber,
      companyId,
      clientId,
      search
    })}`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(res => res.json());

export const updateEntity = async ({ entityId, name, address, taxNumber }) =>
  fetch(`${baseUrl}/entities/${entityId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ name, address, taxNumber })
  }).then(res => res.json());
