import { getToken, baseUrl } from "./index";
import fetch from "isomorphic-fetch";

/**
 * Get the list of clients the current user has access to
 * @param {number} clientId
 */
export const getClients = async () =>
  fetch(`${baseUrl}/clients/`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());
