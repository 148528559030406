export const UPDATE_ALERT = "UPDATE_ALERT";
export const UPDATE_CONFIRM_PROMPT = "SHOW_CONFIRM_PROMPT";
export const UPDATE_ACCESSOR = "UPDATE_ACCESSOR";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const UPDATE_COLLECTOR = "UPDATE_COLLECTOR";

export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_ENTITY = "UPDATE_ENTITY";

export const UPDATE_LOCALE = "UPDATE_LOCALE";
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";

export const UPDATE_FILTER_INVOICE_DATE = "UPDATE_FILTER_INVOICE_DATE";
export const UPDATE_FILTER_PAYMENT_DATE = "UPDATE_FILTER_PAYMENT_DATE";

export const UPDATE_SOCKET = "UPDATE_SOCKET";
