import { SERVER_APP_URL } from "../config.json";

import { auth } from "firebase/app";

export const baseUrl = `${SERVER_APP_URL}/api`;
export const getToken = async () => await auth().currentUser.getIdToken();

export const getFilters = object =>
  `${
    Object.values(object).filter(value => value || value === 0).length > 0
      ? `?`
      : ``
  }${Object.entries(object)
    .filter(([key, value]) => value || value === 0)
    .map(([key, value]) => `${key}=${value}`)
    .join(`&`)}`;

export * from "./users";
export * from "./clients";
export * from "./customers";
export * from "./companies";
export * from "./billableEntities";

export * from "./invoices";
export * from "./payments";

export * from "./comments";
export * from "./files";
