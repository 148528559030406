import React from "react";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function ConfirmPrompt(props) {
  const confirm = useSelector(state => state.confirm);
  const defaultFn = () => {};
  return (
    <Modal
      show={confirm.show}
      onHide={props.onCancel || confirm.onCancel || defaultFn}>
      <Modal.Header closeButton>{confirm.header}</Modal.Header>
      <Modal.Body>{confirm.body}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="error"
          onClick={() => {
            confirm.onCancel();
          }}>
          Cancel
        </Button>
        <Button onClick={confirm.onConfirm}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
}
