import { getToken, baseUrl, getFilters } from "./index";
import fetch from "isomorphic-fetch";

export const getCompanies = async ({
  pageSize,
  pageNumber,
  customerId,
  collectorId,
  search
}) =>
  fetch(
    `${baseUrl}/companies${getFilters({
      pageSize,
      pageNumber,
      customerId,
      collectorId,
      search
    })}`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(res => res.json());
/**
 *
 * @param {{customerId: number, name: string, headOfficeAddress: string, clientId: number}} param0
 */
export const addCompanyToCustomer = async ({
  customerId,
  name,
  headOfficeAddress,
  panNumber,
  clientId
}) =>
  fetch(`${baseUrl}/companies`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      clientId,
      customerId,
      headOfficeAddress,
      panNumber,
      name
    })
  }).then(res => res.json());
/**
 *
 * @param {number} companyId
 */
export const getCompanyById = async companyId =>
  fetch(`${baseUrl}/companies/${companyId}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(res => res.json());

/**
 *
 */
export const updateCompanyData = async (
  companyId,
  { name, headOfficeAddress, customerId }
) =>
  fetch(`${baseUrl}/companies/${companyId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ name, headOfficeAddress, customerId })
  }).then(res => res.json());
