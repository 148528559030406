const pages = [
  { path: "users", groups: ["Developer admin", "admin"] },
  {
    path: "payments",
    groups: [
      "Developer admin",
      "admin",
      "collections",
      "accounts",
      "head_accounts",
    ],
  },

  {
    path: "invoice",
    groups: [
      "Developer admin",
      "admin",
      "collections",
      "accounts",
      "head_accounts",
    ],
  },
  {
    path: "invoice-upload",
    groups: ["Developer admin", "admin", "accounts", "head_accounts"],
  },
  {
    path: "new-invoice",
    groups: ["Developer admin", "admin", "accounts", "head_accounts"],
  },
  {
    path: "new-payment",
    groups: ["Developer admin", "admin", "accounts", "head_accounts"],
  },
  {
    path: "payment",
    groups: [
      "Developer admin",
      "admin",
      "collections",
      "accounts",
      "head_accounts",
    ],
  },
  {
    path: "company",
    groups: [
      "Developer admin",
      "admin",
      "collections",
      "accounts",
      "head_accounts",
    ],
  },
  {
    path: "customer",
    groups: [
      "Developer admin",
      "admin",
      "collections",
      "accounts",
      "head_accounts",
    ],
  },
  {
    path: "customers",
    groups: [
      "Developer admin",
      "admin",
      "accounts",
      "head_accounts",
      "collections",
    ],
  },
  {
    path: "invoices",
    groups: [
      "Developer admin",
      "admin",
      "collections",
      "accounts",
      "head_accounts",
      "head_collections",
    ],
  },
  {
    path: "payment-trends",
    groups: ["Developer admin", "admin", "head_accounts"],
  },
  {
    path: "pivot",
    groups: [
      "Developer admin",
      "admin",
      "head_accounts",
      "head_collections",
      "collections",
      "accounts",
    ],
  },
  // Keep this at the last always
  {
    path: "",
    name: "Home Page",
    sidebar: false,
    // Add all possible roles in this project here
    groups: [
      "Developer admin",
      "admin",
      "collections",
      "accounts",
      "head_accounts",
    ],
  },
];

export default pages;
