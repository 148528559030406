import { getToken, baseUrl, getFilters } from "./index";
import fetch from "isomorphic-fetch";

/**
 * Add a new customer
 * @param {{name: string, collectorId: number, clientId}} customerData
 */
export const addCustomerToClient = async ({ name, collectorId, clientId }) => {
  return await fetch(`${baseUrl}/customers`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      name,
      collectorId,
      ...(clientId ? { clientId } : {})
    })
  }).then(res => res.json());
};

/**
 *
 * @param {number} pageSize
 * @param {number} pageNumber
 * @param {number} clientId
 */
export const getCustomers = async ({
  pageSize,
  pageNumber,
  clientId,
  collectorId,
  search
}) =>
  fetch(
    `${baseUrl}/customers${getFilters({
      pageSize,
      pageNumber,
      clientId,
      collectorId,
      search
    })}`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(response => response.json());

/**
 *
 */
export const getCustomer = async customerId =>
  fetch(`${baseUrl}/customers/${customerId}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(res => res.json());

export const updateCustomer = async ({ name, collectorId, customerId }) =>
  fetch(`${baseUrl}/customers/${customerId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      ...(name ? { name } : {}),
      ...(collectorId ? { collectorId } : {})
    })
  }).then(res => res.json());
