import { getToken, baseUrl, getFilters } from "./index";
import fetch from "isomorphic-fetch";

/**
 *
 * @param {{customerId: number, amount: number, date: date,txnRef: string, clientId: number}} param0
 */
export const addPaymentToCustomer = async ({
  customerId,
  amount,
  date,
  txnRef,
  clientId
}) =>
  fetch(`${baseUrl}/payments`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ customerId, amount, date, clientId, txnRef })
  }).then(res => res.json());

/**
 *
 * @param {{clientId:number, pageSize: number, pageNumber: number}} param0
 */
export const getPaymentsForClient = async ({
  clientId,
  pageSize,
  pageNumber,
  search,
  sortColumn,
  sortOrder,
  status,
  collectorId,
  customerId,
  paymentDateRange
}) =>
  fetch(
    `${baseUrl}/payments${getFilters({
      pageSize,
      pageNumber,
      clientId,
      sortColumn,
      search,
      sortOrder,
      customerId,
      collectorId
    })}${
      paymentDateRange && paymentDateRange.startDate
        ? `&paymentDateRange[]=${paymentDateRange.startDate.format(
            "YYYY-MM-DD"
          )}&paymentDateRange[]=${paymentDateRange.endDate.format(
            "YYYY-MM-DD"
          )}`
        : ``
    }${status >= 0 ? `&status=${status}` : ``}`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(res => res.json());

/**
 *
 * @param {number} id
 */
export const getPaymentById = async id =>
  fetch(`${baseUrl}/payments/${id}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(res => res.json());

/**
 *
 * @param {object[]} invoices
 * @param {number} paymentId
 */

export const addReconEntries = async (invoices, paymentId) =>
  fetch(`${baseUrl}/payments/invoice`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ invoices, paymentId })
  });
/**
 *
 * @param {*} paymentId
 */
export const getReconEntries = async paymentId =>
  fetch(`${baseUrl}/payments/${paymentId}/invoices`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(res => res.json());

/**
 *
 * @param {*} entryId
 */
export const deleteReconEntry = async entryId =>
  fetch(`${baseUrl}/payments/recon/${entryId}`, {
    method: "DELETE",
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(res => res.json());

/**
 *
 * @param {number} id
 * @param {{paymentDate: string}} payload
 */
export const updatePayment = async (id, payload) =>
  fetch(`${baseUrl}/payments/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  });

/**
 *
 * @param {{clientId: number, customerId: number}} parameters
 */
export const getMetaPaymentStats = async ({
  clientId,
  customerId,
  collectorId
}) =>
  fetch(
    `${baseUrl}/payments/meta${getFilters({
      clientId,
      customerId,
      collectorId
    })}`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(res => res.json());
/**
 *
 * @param {number[]} dimensions Dimension indices from the array available
 */
export const getPaymentPivot = async ({
  collectorId,
  customerId,
  dimensions
}) =>
  fetch(
    `${baseUrl}/payments/pivot${getFilters({
      dimensions,
      collectorId,
      customerId
    })}`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(res => res.json());
/**
 *
 * @param {{paymentDateRange: string[],dimension:number}} parameters
 */
export const getPaymentAggregate = async ({
  paymentDateRange,
  dimension,
  customerId,
  collectorId
}) =>
  fetch(
    `${baseUrl}/payments/aggregate${getFilters({
      paymentDateRange,
      dimension,
      customerId,
      collectorId
    })}`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(res => res.json());
/**
 *
 * @param {number} id
 */
export const deletePayment = async id =>
  fetch(`${baseUrl}/payments/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(res => res.json());
