import {
  UPDATE_ALERT,
  UPDATE_ACCESSOR,
  UPDATE_CLIENT,
  UPDATE_COLLECTOR,
  UPDATE_COMPANY,
  UPDATE_CUSTOMER,
  UPDATE_ENTITY,
  UPDATE_CONFIRM_PROMPT,
  UPDATE_FILTER_INVOICE_DATE,
  UPDATE_FILTER_PAYMENT_DATE,
  UPDATE_SOCKET,
  UPDATE_LOCALE,
  UPDATE_CURRENCY
} from "../constants";

export const showSuccessAlert = (header, body) => ({
  type: UPDATE_ALERT,
  payload: {
    alert: { show: true, variant: "success", header, body }
  }
});

export const showErrorAlert = (header, body) => ({
  type: UPDATE_ALERT,
  payload: {
    alert: { show: true, variant: "error", header, body }
  }
});

export const hideAlert = () => ({
  type: UPDATE_ALERT,
  payload: {
    alert: { show: false, variant: "success", header: "", body: "" }
  }
});

export const updateAccessor = accessor => ({
  type: UPDATE_ACCESSOR,
  payload: {
    accessor
  }
});

/**
 *
 * @param {{clientId: number, locale: string, currency: string}} param0
 */

export const updateClient = ({ clientId, locale, currency }) => ({
  type: UPDATE_CLIENT,
  payload: { client: { clientId, currency, locale } }
});

/**
 *
 * @param {number} collectorId
 */
export const updateCollector = collectorId => ({
  type: UPDATE_COLLECTOR,
  payload: { collectorId }
});

/**
 *
 * @param {number} companyId
 */

export const updateCompany = companyId => ({
  type: UPDATE_COMPANY,
  payload: { companyId }
});

/**
 *
 * @param {number} customerId
 */
export const updateCustomer = customerId => ({
  type: UPDATE_CUSTOMER,
  payload: { customerId }
});

/**
 *
 * @param {number} entityId
 */
export const updateEntity = entityId => ({
  type: UPDATE_ENTITY,
  payload: { entityId }
});

/**
 *
 * @param {string} header
 * @param {string} body
 */

export const showConfirmPrompt = (header, body, onConfirm, onCancel) => ({
  type: UPDATE_CONFIRM_PROMPT,
  payload: {
    confirm: {
      show: true,
      header,
      body,
      onConfirm,
      onCancel
    }
  }
});

export const hideConfirmPrompt = () => ({
  type: UPDATE_CONFIRM_PROMPT,
  payload: {
    confirm: {
      show: false,
      header: "",
      body: ""
    }
  }
});
/**
 *
 * @param {{startDate: import("moment"), endDate: import("moment")}} param0
 */
export const updateInvoiceDate = ({ startDate, endDate }) => ({
  type: UPDATE_FILTER_INVOICE_DATE,
  payload: {
    invoiceDateRange: {
      startDate,
      endDate
    }
  }
});

/**
 *
 * @param {{startDate: import("moment"), endDate: import("moment")}} param0
 */
export const updatePaymentDate = ({ startDate, endDate }) => ({
  type: UPDATE_FILTER_PAYMENT_DATE,
  payload: {
    paymentDateRange: {
      startDate,
      endDate
    }
  }
});

/**
 *
 * @param {string} locale
 */
export const updateClientLocale = locale => ({
  type: UPDATE_LOCALE,
  payload: {
    locale
  }
});

/**
 *
 * @param {string} currency
 */
export const updateCurrency = currency => ({
  type: UPDATE_CURRENCY,
  payload: {
    currency
  }
});

/**
 *
 * @param {import("socket.io-client")()} param0
 */
export const updateSocket = socket => ({
  type: UPDATE_SOCKET,
  payload: {
    socket
  }
});
